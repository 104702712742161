import { render, staticRenderFns } from "./ResponsibleLendingPage.vue?vue&type=template&id=69753164&scoped=true&"
import script from "./ResponsibleLendingPage.vue?vue&type=script&lang=js&"
export * from "./ResponsibleLendingPage.vue?vue&type=script&lang=js&"
import style0 from "./ResponsibleLendingPage.vue?vue&type=style&index=0&id=69753164&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69753164",
  null
  
)

export default component.exports