<template>
  <div class="responsible-lending">
    <v-card color="white" width="100%" flat tile>
      <v-container class="py-10">
        <v-row>
          <!-- Responsible Lending -->
          <v-col cols="12">
            <h3 class="heading-h3">Responsible Lending</h3>
          </v-col>

          <v-col cols="12" lg="8">
            <!-- listItems1 -->
            <p
              v-for="(listItem, index) in listItems1"
              :key="index + listItem.text"
              v-html="listItem.text"
              class="b-body-1"
            ></p>

            <!-- listItems3 -->
            <DefaultList :listItems="listItems2" />

            <!-- listItems3 -->
            <p
              v-for="(listItem, index) in listItems3"
              :key="index + listItem.text"
              v-html="listItem.text"
              class="b-body-1"
            ></p>

            <h6 class="h6">
              Yours, <br />
              The Lime Team
            </h6>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import DefaultList from "@/components/shared/lists/DefaultList";

export default {
  name: "LimeLoansResponsiblelending",
  components: { DefaultList },

  data() {
    return {
      listItems1: [
        {
          text: `Lime Group approves a loan application on the basis that the
              customer has a fixed and guaranteed income among other factors.`,
        },
        {
          text: `Prior to receiving a loan with us, you must agree to the terms of
              the Loan Agreement and the Debit Order Authorization. This
              consists of the customer signing the agreements in the form of an
              electronic signature and entering an SMS code (equivalent to a
              signature) sent by Lime Group to the cellphone number provided by
              the client during registration. This makes the electronic Loan
              Agreement and Debit Order Authorization as valid as a personally
              signed paper document.`,
        },
        {
          text: `For our company, we aim to build trusted relationships with every
              customer built on transparency. As a result of this, it is both in
              our best interests and the customer to be clear about the possible
              consequences of default under the Loan Agreement:`,
        },
      ],
      listItems2: [
        {
          text: `We collaborate with leading credit bureaus and information about
              late repayment of loans will be transferred to the credit bureaus.
              This can negatively affect your credit rating and the possibility
              of obtaining new loans on the Lime website, as well as other banks
              and financial institutions.`,
        },
        {
          text: `Failure to comply with the obligations under the Loan Agreement
              does not stop our system from charging interest on outstanding
              repayment amounts. The outstanding amount accrues interest for the
              number of days the loan remains unpaid after the repayment date
              has passed and other penalties are charged.`,
        },
        {
          text: `In the case of late repayment, the customer shall be charged
              penalty fees in accordance with the Terms of Agreement. Interest
              will continue to accrue from the original amount allocated
              according to earlier tariffs, and therefore the interest rate will
              not increase. Our service is responsible and reliable and will not
              charge increased interest but the interest amount charged remains
              the same throughout the loan and even in an event of late
              repayment.`,
        },
        {
          text: `In addition to the late repayment charges, the costs spent on any
              legal proceedings, as well as the costs of collection agencies
              will be incurred by the customer.`,
        },
      ],
      listItems3: [
        {
          text: `If you are not sure whether you will be able to repay the loan,
              then it is best to not use the services of Lime.`,
        },
        {
          text: `As with every lending institution: in the case of late payment, we
              will first contact the customer and negotiate repayment terms. If
              the customer does not cooperate, we will then seek the services of
              debt collection agencies and eventually take legal action through
              lawyers and the court. If you are not able to repay the loan on
              the repayment date, please contact us and we will help you by
              negotiating a repayment plan.`,
        },
        {
          text: `We hope to help you reach your life’s goals and ambitions by
              helping our customers borrow transparently.`,
        },
      ],
    };
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.responsible-lending {
  .h6 {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
  }
}
</style>
